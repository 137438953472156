// Color palette ***********
$black: #242424;
$white: #ffffff;
$pink: #f4dcdf;
$lightpink: #f8e6e9;
$darkpink: #e7aeb6;
$bgpink1: #f8f2f3;
$bgpink2: #eadee0;
// rainbow icon colors
$red: #ff5964;
$orange: #ff9b71;
$yellow: #eed059;
$green: #bce48b;
$skyblue: #7ec2da;
$lilac: #bd93bd;
$rose: #efa8b8;
// *************************

#tech {
  background-color: $bgpink1;
}

.tech {
  .skills-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0px;
    .skills {
      flex: 1;
      background-color: #f8f9fa;
      padding: 20px;
      margin: 10px;
      border-radius: 10px;
      box-shadow: 2px 2px 5px 0px #ababab;
      .skills-title {
        border-bottom: 2px solid lightgray;
      }
      .skills-icons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        padding: 20px 0px 0px;
        color: $white;
        .icon {
          font-size: 0.9rem;
          padding: 0px 10px;
          margin: 3px;
          background-color: $white;
          border: transparent;
          border-radius: 5px;
          box-shadow: 1px 1px 3px 0px #ababab;
          cursor: auto;
        }
        .text {
          color: gray;
          font-weight: 600;
          margin-left: 5px;
        }
        .red {
          color: $red;
        }
        .orange {
          color: $orange;
        }
        .yellow {
          color: $yellow;
        }
        .green {
          color: $green;
        }
        .skyblue {
          color: $skyblue;
        }
        .lilac {
          color: $lilac;
        }
        .rose {
          color: $rose;
        }
      }
    }
  }
}

// MEDIA QUERY FOR SCREENS 480PX OR LARGER
@media only screen and (min-width: 480px) {
}

// MEDIA QUERY FOR SCREENS 768PX OR LARGER
@media only screen and (min-width: 768px) {
  .tech {
    padding: 10px 0px 0px;
    .skills-wrapper {
      flex-direction: row;
      // flex-wrap: nowrap;
      padding: 0px 20px;
      .skills {
        margin: 10px;
      }
    }
  }
}

// MEDIA QUERY FOR SCREENS 992PX OR LARGER
@media only screen and (min-width: 992px) {
  .tech {
    h1 {
      margin-top: 10px;
    }
    .skills-wrapper {
      margin-bottom: 10px;
    }
  }
}
