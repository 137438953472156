// Color palette ***********
$black: #242424;
$white: #ffffff;
$pink: #f4dcdf;
$lightpink: #f8e6e9;
$darkpink: #e7aeb6;
$bgpink1: #f8f2f3;
$bgpink2: #eadee0;
// rainbow icon colors
$red: #ff5964;
$orange: #ff9b71;
$yellow: #eed059;
$green: #bce48b;
$skyblue: #7ec2da;
$lilac: #bd93bd;
$rose: #efa8b8;
// *************************

#about {
  background-color: $bgpink1;
}

.about1 {
  padding: 130px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  .box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0px 0px 30px;
    width: 300px;
    img {
      width: 100%;
      margin: auto auto;
    }
  }
  .box2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: left;
    padding: 0px;

    h1 {
      text-align: center;
      padding: 0px;
      margin: 0px;
    }

    p {
      text-align: justify;
      .pixel {
        font-weight: 500;
      }
    }

    .bullets {
      display: flex;
      flex-direction: column;
      align-content: center;
      margin: 0px 0px 16px;
      .list {
        text-align: left;
        margin: 0px;
        padding: 0px;
        ul {
          margin: 0px;
          li {
            color: rgb(82, 82, 82);
            margin: 5px 0px;
            span {
              color: black;
              background-color: $pink;
              padding: 0px 5px;
              margin-left: 6px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.about2 {
  width: 100%;
  padding: 30px 0px 20px;
  background-image: url("../images/aboutbg.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: 38px 38px;
  .about-interests {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px;
    .box1 {
      display: none;
    }
    .box2 {
      padding: 0px 20px;
      margin: 0px;
      img {
        height: 50px;
        margin-bottom: -28px;
      }
      p {
        padding: 50px 20px 30px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 0px #ababab;
      }
    }
  }
}

// MEDIA QUERY FOR SCREENS 480PX OR LARGER
@media only screen and (min-width: 480px) {
  .about1 {
    padding-top: 140px;
    .box1 {
      width: 375px;
    }
    .box2 {
      padding: 0px 10px;
    }
  }
  .about2 {
    padding: 20px 20px;
  }
}

// MEDIA QUERY FOR SCREENS 768PX OR LARGER
@media only screen and (min-width: 768px) {
  .about1 {
    .box2 {
      .bullets {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        // border: 1px solid blue;
        .list {
          // margin-left: 50px;
          // border: 1px solid red;
          ul {
            margin-bottom: 0px;
            li {
              // text-align: left;
            }
          }
        }
      }
    }
  }
  .about2 {
    .about-interests {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0px 20px;
      margin: 20px 0px;
      .box1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        height: 250px;
        padding: 0px;
        margin: 0px 20px;
        img {
          width: 100%;
        }
      }
      .box2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        max-width: 750px;
        flex: 1;
        padding: 0px 20px;
        img {
          z-index: 10;
          height: 50px;
          margin-bottom: -28px;
        }
        p {
          padding: 50px 20px 30px;
          background-color: $white;
          border-radius: 10px;
        }
      }
    }
  }
}

// MEDIA QUERY FOR SCREENS 992PX OR LARGER
@media only screen and (min-width: 992px) {
  .about1 {
    padding: 210px 50px 10px;
  }
  .about2 {
    padding: 20px 30px;
    .about-interests {
      margin: 30px 0px;
      .box1 {
        height: 300px;
      }
      .box2 {
        p {
          padding: 50px 40px 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .about1 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .box1 {
      margin-right: 40px;
    }
    .box2 {
      padding: 0px;
      margin-left: 40px;
    }
  }
  .about2 {
    padding: 20px 40px;
    .about-interests {
      padding: 0px 20px;
      justify-content: space-evenly;
      .box1 {
        height: 350px;
      }
      .box2 {
        max-width: 800px;
        p {
          padding: 70px 50px 50px;
        }
      }
    }
  }
}
