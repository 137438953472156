// Color palette ***********
$black: #242424;
$white: #ffffff;
$pink: #f4dcdf;
$lightpink: #f8e6e9;
$darkpink: #e7aeb6;
$bgpink1: #f8f2f3;
$bgpink2: #eadee0;
// rainbow icon colors
$red: #ff5964;
$orange: #ff9b71;
$yellow: #eed059;
$green: #bce48b;
$skyblue: #7ec2da;
$lilac: #bd93bd;
$rose: #efa8b8;
// *************************

#design {
  background-color: $bgpink1;
}

.design {
  padding: 10px 0px;
}

.carousel {
  width: 700px;
  padding: 10px 0px 40px;
  button.slick-arrow {
    display: none !important;
  }

  button {
    width: 50px;
    height: 50px;
    margin: auto -30px;
  }

  .slick-dots li button::before {
    font-size: 16px;
    width: 40px;
    height: 40px;
    text-align: center;
    opacity: 0.3;
    color: $darkpink;
    background-color: $bgpink1;
    margin-top: 20px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  .img-slider {
    // width: 50%;
    padding: 20px 0px;
    margin: 10px 0px;
    background-color: $bgpink2;
    display: flex !important;
    justify-content: center;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    opacity: 0.75;
    color: $darkpink;
  }
}

// MEDIA QUERY FOR SCREENS 480PX OR LARGER
@media only screen and (min-width: 480px) {
}

// MEDIA QUERY FOR SCREENS 768PX OR LARGER
@media only screen and (min-width: 768px) {
  .carousel {
    button.slick-arrow {
      display: block !important;
    }
  }
}

// MEDIA QUERY FOR SCREENS 992PX OR LARGER
@media only screen and (min-width: 992px) {
  .design {
    padding: 10px 0px;
  }
  .carousel {
    width: 800px;
    padding: 10px 0px 40px;

    button {
      width: 50px;
      height: 50px;
      margin: auto -30px;
    }

    .slick-dots li button::before {
      font-size: 16px;
      width: 40px;
      height: 40px;
      text-align: center;
      opacity: 0.3;
      color: $darkpink;
      background-color: $bgpink1;
      margin-top: 20px;
    }

    .slick-dots li.slick-active button:before {
      opacity: 1;
    }

    .img-slider {
      padding: 20px 0px;
      margin: 10px 0px;
      background-color: $bgpink2;
      display: flex !important;
      justify-content: center;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 40px;
      opacity: 0.75;
      color: $darkpink;
    }
  }
}
