// Color palette ***********
$black: #242424;
$white: #ffffff;
$pink: #f4dcdf;
$lightpink: #f8e6e9;
$darkpink: #e7aeb6;
$bgpink1: #f8f2f3;
$bgpink2: #eadee0;
// rainbow icon colors
$red: #ff5964;
$orange: #ff9b71;
$yellow: #eed059;
$green: #bce48b;
$skyblue: #7ec2da;
$lilac: #bd93bd;
$rose: #efa8b8;
// *************************

footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 10px 30px;
  width: 100%;

  .col1 {
    width: 65px;
    img {
      width: 100%;
      margin: 10px 0px;
    }
  }
  .col2 {
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 0px 40px;

    div {
      display: inline;
      white-space: nowrap;
    }
  }
  .col3 {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    color: $darkpink;
    align-items: center;
    a {
      color: $darkpink;
      text-decoration: none;
    }
    .icon {
      font-size: 3.5rem; // controls icon size
    }
    .icon2 {
      font-size: 4.4rem; // controls icon size
      padding: 0px 10px;
    }
  }
}

// MEDIA QUERY FOR SCREENS 480PX OR LARGER
@media only screen and (min-width: 480px) {
}

// MEDIA QUERY FOR SCREENS 768PX OR LARGER
@media only screen and (min-width: 768px) {
  footer {
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 10px 30px;
    width: 100%;

    .col1 {
      width: 115px;
      img {
        width: 100%;
      }
    }
    // .col2 {
    //   white-space: nowrap;
    // }
  }
}

// MEDIA QUERY FOR SCREENS 992PX OR LARGER
@media only screen and (min-width: 992px) {
}

// CSS FOR WAVES ABOVE FOOTER
.path-0 {
  animation: pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0% {
    d: path(
      "M 0,400 C 0,400 0,133 0,133 C 187.7333333333333,121.13333333333333 375.4666666666666,109.26666666666665 516,123 C 656.5333333333334,136.73333333333335 749.8666666666668,176.0666666666667 896,182 C 1042.1333333333332,187.9333333333333 1241.0666666666666,160.46666666666664 1440,133 C 1440,133 1440,400 1440,400 Z"
    );
  }
  25% {
    d: path(
      "M 0,400 C 0,400 0,133 0,133 C 182.13333333333333,153.66666666666666 364.26666666666665,174.33333333333331 521,167 C 677.7333333333333,159.66666666666669 809.0666666666666,124.33333333333333 958,114 C 1106.9333333333334,103.66666666666667 1273.4666666666667,118.33333333333334 1440,133 C 1440,133 1440,400 1440,400 Z"
    );
  }
  50% {
    d: path(
      "M 0,400 C 0,400 0,133 0,133 C 134.26666666666665,153.13333333333333 268.5333333333333,173.26666666666665 411,166 C 553.4666666666667,158.73333333333335 704.1333333333334,124.06666666666668 877,114 C 1049.8666666666666,103.93333333333332 1244.9333333333334,118.46666666666667 1440,133 C 1440,133 1440,400 1440,400 Z"
    );
  }
  75% {
    d: path(
      "M 0,400 C 0,400 0,133 0,133 C 195.33333333333337,141.93333333333334 390.66666666666674,150.86666666666665 565,162 C 739.3333333333333,173.13333333333335 892.6666666666665,186.4666666666667 1035,182 C 1177.3333333333335,177.5333333333333 1308.6666666666667,155.26666666666665 1440,133 C 1440,133 1440,400 1440,400 Z"
    );
  }
  100% {
    d: path(
      "M 0,400 C 0,400 0,133 0,133 C 187.7333333333333,121.13333333333333 375.4666666666666,109.26666666666665 516,123 C 656.5333333333334,136.73333333333335 749.8666666666668,176.0666666666667 896,182 C 1042.1333333333332,187.9333333333333 1241.0666666666666,160.46666666666664 1440,133 C 1440,133 1440,400 1440,400 Z"
    );
  }
}

.path-1 {
  animation: pathAnim-1 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0% {
    d: path(
      "M 0,400 C 0,400 0,266 0,266 C 204.13333333333333,275.06666666666666 408.26666666666665,284.1333333333333 541,284 C 673.7333333333333,283.8666666666667 735.0666666666667,274.53333333333336 873,270 C 1010.9333333333333,265.46666666666664 1225.4666666666667,265.73333333333335 1440,266 C 1440,266 1440,400 1440,400 Z"
    );
  }
  25% {
    d: path(
      "M 0,400 C 0,400 0,266 0,266 C 132.13333333333333,279.4666666666667 264.26666666666665,292.93333333333334 432,300 C 599.7333333333333,307.06666666666666 803.0666666666666,307.73333333333335 977,301 C 1150.9333333333334,294.26666666666665 1295.4666666666667,280.1333333333333 1440,266 C 1440,266 1440,400 1440,400 Z"
    );
  }
  50% {
    d: path(
      "M 0,400 C 0,400 0,266 0,266 C 139.2,293.8666666666667 278.4,321.7333333333333 457,315 C 635.6,308.2666666666667 853.5999999999999,266.93333333333334 1024,253 C 1194.4,239.06666666666666 1317.2,252.53333333333333 1440,266 C 1440,266 1440,400 1440,400 Z"
    );
  }
  75% {
    d: path(
      "M 0,400 C 0,400 0,266 0,266 C 184,288.4 368,310.8 509,301 C 650,291.2 748,249.20000000000002 896,238 C 1044,226.79999999999998 1242,246.39999999999998 1440,266 C 1440,266 1440,400 1440,400 Z"
    );
  }
  100% {
    d: path(
      "M 0,400 C 0,400 0,266 0,266 C 204.13333333333333,275.06666666666666 408.26666666666665,284.1333333333333 541,284 C 673.7333333333333,283.8666666666667 735.0666666666667,274.53333333333336 873,270 C 1010.9333333333333,265.46666666666664 1225.4666666666667,265.73333333333335 1440,266 C 1440,266 1440,400 1440,400 Z"
    );
  }
}
